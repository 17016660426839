/* eslint-disable react/jsx-props-no-spreading */
import { match } from 'ts-pattern'

import { Layout } from '../common/types/types'
import { ConnectedLayout } from './connected'
import { NotConnectedLayout } from './notConnected'

export const withLayout =
  (layout: Layout) =>
  <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    match(layout)
      .with(Layout.connected, () => (
        <ConnectedLayout>
          <Component {...props} />
        </ConnectedLayout>
      ))
      .with(Layout.notConnected, () => (
        <NotConnectedLayout>
          <Component {...props} />
        </NotConnectedLayout>
      ))
      .exhaustive()
