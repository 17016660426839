import { Logo, LogoType } from '@stakenow/design-system'
import dynamic from 'next/dynamic'
import React, { FC } from 'react'

import { Wallet } from '../Wallet'

const ThemeToggle = dynamic(
  () => import('../ThemeToggle').then(x => x.ThemeToggle).catch(err => console.log(err)) as any,
  {
    ssr: false,
  },
)

const Header: FC = () => (
  <header
    className="sticky top-0 left-0 right-0 z-30 bg-white dark:bg-gray-800 
    backdrop-filter dark:bg-opacity-90 bg-opacity-90 backdrop-blur-xl border-b md:border-none border-gray-200 dark:border-gray-700 border-opacity-40 dark:border-opacity-40"
  >
    <div className="max-w-screen-2xl w-full mx-auto flex justify-end items-center gap-x-4 py-6 px-6 sm:py-4 sm:px-6">
      <div className="mr-auto">
        <Logo type={LogoType.full} responsive={false} />
      </div>
      <ThemeToggle />
      <Wallet />
    </div>
  </header>
)

export default Header
