import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { Balance, LoadingIndicator } from '@stakenow/design-system'
import { pathOr } from 'ramda'
import React, { FC, useEffect } from 'react'

import { makeAccountVar } from '../../common/graphql'
import { formatAmount } from '../../common/utils'
import { GET_BALANCE } from './balance.gql'

const HeaderBalance: FC = () => {
  const [loadBalance, { loading, data }] = useLazyQuery(GET_BALANCE)
  const { xtz } = pathOr({}, ['getAccount', 'balance'])(data)
  const { pkh } = useReactiveVar(makeAccountVar)

  useEffect(() => {
    if (pkh) {
      loadBalance()
    }
  }, [pkh])

  if (loading) {
    return <LoadingIndicator />
  }

  return xtz ? <Balance balance={formatAmount(parseFloat(xtz))} /> : null
}

export default HeaderBalance
