import { Logo, LogoType, Socials } from '@stakenow/design-system'
import React, { FC } from 'react'

import { Size } from '../../../common/types'
import { Credits } from '../../Credits'
import { Legal } from '../../Legal'

const Footer: FC = () => (
  <footer className="mx-auto px-6 py-8 md:px-4 md:py-6 bg-white dark:bg-gray-800 relative z-20">
    <div className="text-center">
      <div className="mx-auto w-48">
        <Logo type={LogoType.full} size={Size.medium} responsive={false} />
        <div className="pt-12">
          <Socials size={Size.large} />
        </div>
      </div>
      <Legal />
    </div>
    <div className="pb-6 text-center">
      <p className="text-gray-400 text-xs tracking-wide">
        &copy; 2022 StakeNow<sup>®</sup> is a trademark of vDL Digital Ventures GmbH. All rights reserved. <Credits />
      </p>
    </div>
  </footer>
)

export default Footer
