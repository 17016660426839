import { Logo, LogoType, NavigationToggle, Socials } from '@stakenow/design-system'
import dynamic from 'next/dynamic'
import { FC, ReactNode, useState } from 'react'

import { Size } from '../common/types'
import { Balance } from '../modules/Balance'
import { Credits } from '../modules/Credits'
import { Legal } from '../modules/Legal'
import { Navigation } from '../modules/Navigation'
import { Wallet } from '../modules/Wallet'

const ThemeToggle = dynamic(
  () => import('../modules/ThemeToggle').then(x => x.ThemeToggle).catch(err => console.log(err)) as any,
  {
    ssr: false,
  },
)

interface LayoutProps {
  children: ReactNode
}

export const ConnectedLayout: FC<LayoutProps> = ({ children }) => {
  const [isOpen, setOpen] = useState(false)

  const toggleNavClass = isOpen ? 'translate-x-0 bg-white dark:bg-gray-800' : '-translate-x-full lg:translate-x-0'
  const toggleOverlayClass = isOpen
    ? 'fixed top-0 bottom-0 left-0 right-0 bg-gray-900 opacity-50 transitions-colors flex mt-16'
    : 'hidden opacity-0'

  return (
    <div className="min-h-screen">
      <header
        className="sticky top-0 left-0 right-0 z-20 bg-white dark:bg-gray-800 
        backdrop-filter dark:bg-opacity-90 bg-opacity-90 backdrop-blur-xl border-b md:border-none border-gray-200 dark:border-gray-700 border-opacity-40 dark:border-opacity-40"
      >
        <div className="max-w-screen-2xl w-full mx-auto flex justify-end items-center gap-x-4 py-6 px-6 sm:py-4 sm:px-6">
          <div className="mr-auto">
            <Logo type={LogoType.full} responsive={false} />
          </div>
          <ThemeToggle />
          <div className="hidden lg:block">
            <Balance />
          </div>
          <div className="hidden lg:block">
            <Wallet />
          </div>
          <NavigationToggle isOpen={isOpen} onToggle={() => setOpen(!isOpen)} />
        </div>
      </header>
      <div className="max-w-8xl mx-auto px-6 sm:px-6 md:px-8 pt-6 md:pt-0 h-full">
        <div
          className={`transition-all flex fixed z-20 inset-0 top-16 lg:top-20 justify-between flex-col left-[max(0px,calc(50%-48rem))] right-auto w-[19.5rem] pb-10 pt-4 px-8 overflow-y-auto ${toggleNavClass}`}
        >
          <div>
            <Navigation />
            <div className="lg:hidden flex flex-col mt-4">
              <div className="mb-4">
                <Balance />
              </div>
              <div>
                <Wallet />
              </div>
            </div>
          </div>
          <div className="mt-5 flex justify-between flex-col align-middle">
            <Socials size={Size.large} />
          </div>
        </div>
        <div className="lg:pl-[19.5rem] flex flex-col pb-10 md:pb-6 h-full">
          <div className="pb-6 md:pb-10">{children}</div>
          <div className="flex justify-center flex-col md:flex-row mt-auto pb-6 md:pb-0">
            <Legal />
            <div className="justify-center md:justify-start text-gray-400 text-xs tracking-wide flex pt-0 md:pt-6 md:pl-4">
              <Credits />
            </div>
          </div>
        </div>
      </div>
      <div className={`transition-all ${toggleOverlayClass}`} onClick={() => setOpen(!isOpen)} aria-hidden="true" />
    </div>
  )
}
