import Link from 'next/link'
import React, { FC } from 'react'

export const Legal: FC = () => (
  <ul className="flex justify-center space-x-4 text-xs text-gray-500 dark:text-gray-400 pt-0 md:pt-6 mb-5">
    <li>
      <Link href="/legal-notice">Legal notice</Link>
    </li>
    <li>
      <Link href="/privacy-policy">Privacy policy</Link>
    </li>
    <li>
      <Link href="/terms-conditions">Terms &amp; Conditions</Link>
    </li>
  </ul>
)
