import React, { FC } from 'react'

export const Credits: FC = () => (
  <>
    Built with&nbsp;
    <a href="https://tzkt.io" target="_blank">
      TzKT API
    </a>
  </>
)
