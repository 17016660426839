import { FC, ReactNode } from 'react'

import { Header } from '../modules/Header'
import { Footer } from '../modules/LandingPage/Footer'

interface NotConnectedProps {
  children: ReactNode
}

export const NotConnectedLayout: FC<NotConnectedProps> = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
)
